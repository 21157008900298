import { environment } from "environments/environment";

export class Logger {
    private _scope: string = '';
    private _debugMode: boolean = !environment.production;
    private _disableScopedFilters: boolean = environment.logSkipScopeFilters;
    private _scopeFilters: string[] = environment.logScopes;
    private _levelFilters: string[] = environment.logLevels;

    constructor( scope : string ) { this._scope = scope; }

    get info() {
        if ( this._debugMode && this._levelFilters.includes('info') && (this._disableScopedFilters || this._scopeFilters.includes(this._scope))) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.info.bind( window.console, '📗 INFO', timestamp, this._scope);
        } else {
            return () => {};
        }
    }

    get trace() {
        if ( this._debugMode && this._levelFilters.includes('trace') && (this._disableScopedFilters || this._scopeFilters.includes(this._scope))) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.trace.bind( window.console, '%c📓 TRACE', 'color:gray', timestamp, this._scope );
        } else {
            return () => {};
        }
    }


    get debug() {
        if ( this._debugMode && this._levelFilters.includes('debug') && (this._disableScopedFilters || this._scopeFilters.includes(this._scope))) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.debug.bind( window.console, '%c📔 DEBUG', 'color:yellow', timestamp, this._scope );
        } else {
            return () => {};
        }
    }

    get warn() {
        if ( this._levelFilters.includes('warn')) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.warn.bind( window.console, '%c📙 WARN', 'color:orange', timestamp, this._scope );
        } else {
            return () => {};
        }
    }    

    get error() {
        if ( this._levelFilters.includes('error')) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.error.bind( window.console, '%c📕 ERROR', 'color:red', timestamp, this._scope );
        } else {
            return () => {};
        }
    }    

    get log() {
        if ( this._debugMode && (this._disableScopedFilters || this._scopeFilters.includes(this._scope))) {
            const now = new Date();
            const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
            return window.console.log.bind( window.console, '%c📘 LOG', 'color:light-blue', timestamp, this._scope );
        } else {
            return () => {};
        }
    }

}