export const environment = {
    production: false,
    allowSignUp: true,
    cognitoUserPoolId: 'ap-southeast-1_ahqFTiDBN',
    cognitoAppClientId: '5sd1jp78pbphi0q29h1kispt1h',
    cognitoIdentityPoolId: 'ap-southeast-1:9d1d2eef-3700-42a4-819c-dc44db62f18c',
    apiBaseUrl: 'https://api.stroomer.co.id/dev/',
    consoleDashboardUrl: 'https://console.stroomer.id/overview',
    landingPageSubdomain: 'www',
    consolePageSubdomain: 'console',
    oauthDomain: `stroomer-platform-dev.auth.ap-southeast-1.amazoncognito.com`,
    oauthRedirectSignIn: ['http://localhost:4200'],
    oauthRedirectSignOut: ['http://localhost:4200/sign-out'],
    logScopes: [''],
    logLevels: ['warn','debug','info','error','trace'],
    logSkipScopeFilters: false,
    googleMapApiKey: "AIzaSyCHP9I77f_cRoxih4Ro_OvpCoJBWCZpYY8"
};
